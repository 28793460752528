const permissions = [
  {
    path: '/profiles',
    name: 'profiles.list',
    component: () => import('@/views/permission/profile/ProfilesList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
  {
    path: '/profiles/new',
    name: 'profiles.new',
    component: () => import('@/views/permission/profile/ProfileForm.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
  {
    path: '/profiles/:uuid/edit',
    name: 'profiles.edit',
    component: () => import('@/views/permission/profile/ProfileForm.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
]

export default permissions
