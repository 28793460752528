const state = {
  unities: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_UNITIES(state, payload) {
    state.unities = payload
  },
}

const actions = {
  getUnities: async ({ commit }) => {
    try {
      const data = await window.http.get('unities', { loading: false })
      if (data.error) {
        return []
      }
      commit('SET_UNITIES', data)
      return data
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
