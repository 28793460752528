const users = [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/views/users/UsersList.vue'),
    meta: {
      roles: ['super_admin'],
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/users/account-settings/AccountSettings.vue'),
    meta: {
      public: true,
      roles: ['super_admin', 'user', 'professional', 'admin'],
    },

  },
]

export default users
