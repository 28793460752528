export default {
  namespaced: true,
  state: {
    states: [],
  },

  mutations: {
    // eslint-disable-next-line no-shadow
    SET_ALL_STATES(state, payload) {
      state.states = payload
    },

  },
  actions: {
    getStates: async ({ commit, state }) => {
      try {
        if (state.states.length > 0) {
          return state.states
        }
        const data = await window.http.get('states')
        commit('SET_ALL_STATES', data)
        return data
      } catch (error) {
        return error
      }
    },
  },
}
