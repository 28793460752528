import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, userRole } from '@/auth/utils'
import store from '@/store'

import password from '@/router/password'
import users from '@/router/users'
import clients from '@/router/clients'
import opportunity from '@/router/opportunity'
import unity from '@/router/unity'
import professional from '@/router/professional'
import opportunitiesdoctor from '@/router/opportunities_doctor'
import permissions from '@/router/permission'
// :routes-imports://

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      roles: ['super_admin', 'user', 'professional', 'admin'],
      public: true,
    },

  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...opportunity,
    ...opportunitiesdoctor,
    ...unity,
    ...professional,
    ...permissions,
    // :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.public || to.name === 'error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
    || to.name === 'recuperar-senha' || to.name === 'auth' || to.name === 'code.login') {
    return next()
  }
  if (to.meta?.public === true) {
    next()
    return true
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('/login')
      return false
    }

    const permissionList = await router.app.$store.dispatch('menu/getPermissions')
    const hasPermission = permissionList.find(item => item.route == to.name)
    const roles = to.meta.roles || []

    if (roles.length > 0 && !hasPermission) {
      router.push({ name: 'error-404' })
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
