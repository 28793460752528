const professional = [
  {
    path: '/professionals',
    name: 'professionals.list',
    component: () => import('@/views/professionals/ProfessionalList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },

  {
    path: '/opportunities/:uuid/detail',
    name: 'opportunities.admin.detail',
    component: () => import('@/views/opportunity/OpportunityAdminDetail.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
]

export default professional
