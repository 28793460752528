const opportunitiesdoctor = [
  {
    path: '/opportunities-doctor',
    name: 'opportunitiesdoctor.list',
    component: () => import('@/views/opportunities_doctor/OpportunitiesDoctorList.vue'),
    meta: {
      roles: ['super_admin', 'user', 'professional', 'admin'],
    },
  },
  {
    path: '/opportunities-doctor-list/:type',
    name: 'opportunitiesdoctor.list.type',
    component: () => import('@/views/opportunities_doctor/OpportunitiesList.vue'),
    meta: {
      roles: ['super_admin', 'user', 'professional', 'admin'],
    },
  },
  {
    path: '/opportunities-doctor/:uuid/show',
    name: 'opportunitiesdoctor.show',
    component: () => import('@/views/opportunities_doctor/OpportunitiesShow.vue'),
    meta: {
      roles: ['super_admin', 'user', 'professional', 'admin'],
    },
  },
]

export default opportunitiesdoctor
