export default {
  namespaced: true,
  state: {
    opportunities: [],
    dashboardOpportunities: [],
  },

  mutations: {
    // eslint-disable-next-line no-shadow
    SET_opportunities(state, payload) {
      state.opportunities = payload
    },
    SET_DASHBOARD_OPPORTUNITIES(state, payload) {
      state.dashboardOpportunities = payload
    },

  },
  actions: {
    getOpportunities: async ({ commit }, status) => {
      try {
        const data = await window.http.get(`/me/opportunities?search=status:${status}`)
        commit('SET_opportunities', data)
        return data
      } catch (error) {
        return error
      }
    },

    getDashBoardOpportunities: async ({ commit }, status) => {
      try {
        const data = await window.http.get(`/opportunities?search=status:${status}&include=total`, { loading: false })
        commit('SET_DASHBOARD_OPPORTUNITIES', data)
        return data
      } catch (error) {
        return error
      }
    },
    getOpportunityByUuid: async ({ commit }, opportunity) => {
      try {
        const data = await window.http.get(`/opportunities/${opportunity}?include=professionals`)
        commit('SET_DASHBOARD_OPPORTUNITIES', data)
        return data
      } catch (error) {
        return error
      }
    },

    applyTo: async ({ commit }, opportunity) => {
      try {
        const data = await window.http.post(`/professionals/opportunities/${opportunity}/apply`)
        return data
      } catch (error) {
        return error
      }
    },
  },
}
