import { getUserData } from '@/auth/utils'

const state = {
  menu: null,
  permissions: null,
}

const getters = {
  items: state => state.menu,
  permissions: state => state.permissions,
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_USER_MENU(state, payload) {
    state.menu = payload
  },

  SET_USER_PERMISSIONS(state, payload) {
    state.permissions = payload
  },
}

const actions = {
  getMenu: async ({ commit }) => {
    try {
      const data = await window.http.get('auth/permissions', { loading: false })
      if (data.error) {
        return null
      }
      commit('SET_USER_MENU', data)
      return data
    } catch (error) {
      return error
    }
  },
  getPermissions: async ({ state, commit }) => {
    const user = getUserData()
    if (!user) {
      return
    }
    if (state.permissions === null) {
      const data = await window.http.get('auth/permissions/list', { loading: false })
      commit('SET_USER_PERMISSIONS', data)
      return data
    }
    return state.permissions
  },
  setMenu: async ({ commit }, menu) => {
    commit('SET_USER_MENU', menu)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
