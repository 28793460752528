import { arrayify } from 'vue-flatpickr-component/src/util'

export default {
  namespaced: true,
  state: {
    cities: [],
  },

  mutations: {
    // eslint-disable-next-line no-shadow
    SET_ALL_CITIES(state, payload) {
      state.cities = payload
    },
  },
  actions: {
    getCities: async ({ commit, state }) => {
      try {
        if (state.cities.length > 0) {
          return state.cities
        }
        let cities = window.localStorage.getItem('city_list')
        if (cities !== null) {
          cities = JSON.parse(cities)
          commit('SET_ALL_CITIES', cities)
          return cities
        }
        const data = await window.http.get('cities')
        commit('SET_ALL_CITIES', data)
        window.localStorage.setItem('city_list', JSON.stringify(data))
        return data
      } catch (error) {
        return error
      }
    },
    getCitiesByState: async ({ dispatch }, uf) => {
      const cities = await dispatch('cities/getCities', {}, { root: true })
      if (!Array.isArray(uf)) {
        uf = [uf]
      }
      let cityList = []
      uf.forEach(item => {
        cityList = cityList.concat(cities.filter(city => city.state_id === item))
      })
      return cityList
    },
  },
}
