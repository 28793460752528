import Vue from 'vue'

const ConfirmBox = {
  async confirm(message, title, onConfirm, options) {
    const vm = new Vue()
    const h = vm.$createElement
    const hMessage = h('div', { domProps: { innerHTML: message } })
    return vm.$bvModal.msgBoxConfirm(
      [hMessage],
      {
        title,
        size: 'sm',
        okVariant: options?.okVariant || 'primary',
        okTitle: options?.cancelTitle || 'Sim',
        cancelTitle: options?.cancelTitle || 'Não',
        cancelVariant: options?.cancelVariant || 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      },
    )
  },
}
Vue.prototype.$confirmBox = ConfirmBox
window.$confirmBox = ConfirmBox
export default ConfirmBox
